<template>
    <div class="wrap" :class="{ wrap_en: $i18n.locale == 'EN' }">
        <my-header></my-header>
        <div class="section">
            <div :class="$i18n.locale == 'EN' ? 'banner banner_padding' : 'banner'">
                <transition name="slide-fade">
                    <div v-if="show">
                        <p>{{ $t('Public.Service.services.title') }}</p>
                        <div class="banner-txt">{{ $t('Public.Service.services.text1') }}</div>
                        <div class="banner-txt">{{ $t('Public.Service.services.text2') }}</div>
                    </div>
                </transition>
            </div>
            <div class="content">
                <p class="detail_title">{{ $t('Public.Service.scopeOfService.title') }}</p>
                <ul class="service_type">
                    <li class="frist_li">
                        <div class="service_text">
                            <div class="service_text_top">
                                <div>
                                    <img src="../../public/images/service/service_icon1.png" />
                                </div>
                                <p>{{ $t('Public.Service.scopeOfService.list[0].title') }}</p>
                            </div>
                            <div class="service_text_middle">
                                <!-- <p>{{ $t('Public.Service.scopeOfService.list[0].small_title1') }}</p> -->
                                <div class="middle_txt">{{ $t('Public.Service.scopeOfService.list[0].text1') }}</div>
                                <!-- <p>{{ $t('Public.Service.scopeOfService.list[0].small_title2') }}</p> -->
                                <div class="middle_txt">{{ $t('Public.Service.scopeOfService.list[0].text2') }}</div>
                                <div class="middle_txt">{{ $t('Public.Service.scopeOfService.list[0].text3') }}</div>
                            </div>
                            <div class="service_text_bottom" @click="handleRoute('/UBIService')">{{ $t('Public.Service.scopeOfService.list[0].look') }} >></div>
                        </div>
                        <div class="service_img">
                            <img src="../../public/images/service/service_1.png" />
                        </div>
                    </li>
                    <li>
                        <div class="service_img">
                            <img src="../../public/images/service/service_2.png" />
                        </div>
                        <div class="service_text">
                            <div class="service_text_top">
                                <div>
                                    <img src="../../public/images/service/service_icon2.png" />
                                </div>
                                <p>{{ $t('Public.Service.scopeOfService.list[1].title') }}</p>
                            </div>
                            <div class="service_text_middle middle_txt">
                                {{ $t('Public.Service.scopeOfService.list[1].text1') }}
                            </div>
                            <div class="service_text_middle middle_txt">
                                {{ $t('Public.Service.scopeOfService.list[1].text2') }}
                            </div>
                            <div class="service_text_middle middle_txt">
                                {{ $t('Public.Service.scopeOfService.list[1].text3') }}
                            </div>
                            <div class="service_text_middle middle_txt">
                                {{ $t('Public.Service.scopeOfService.list[1].text4') }}
                            </div>
                            <!-- <div class="service_text_bottom text_right" @click="handleRoute('/NEVService')">{{ $t('Public.Service.scopeOfService.list[1].look') }} >></div> -->
                            <div class="service_text_bottom text_right" @click="handleRoute('/ControlService')">{{ $t('Public.Service.scopeOfService.list[1].look') }} >></div>
                        </div>
                    </li>
                    <li>
                        <div class="service_text">
                            <div class="service_text_top">
                                <div>
                                    <img src="../../public/images/service/service_icon4.png" />
                                </div>
                                <p>{{ $t('Public.Service.scopeOfService.list[2].title') }}</p>
                            </div>
                            <div class="service_text_middle middle_txt">
                                {{ $t('Public.Service.scopeOfService.list[2].text1') }}
                            </div>
                            <div class="service_text_middle middle_txt">
                                {{ $t('Public.Service.scopeOfService.list[2].text2') }}
                            </div>
                            <div class="service_text_middle middle_txt">
                                {{ $t('Public.Service.scopeOfService.list[2].text3') }}
                            </div>
                            <!-- <div class="service_text_bottom" @click="handleRoute('/ControlService')">{{ $t('Public.Service.scopeOfService.list[2].look') }} >></div> -->
                            <div class="service_text_bottom" @click="handleRoute('/SecurityService')">{{ $t('Public.Service.scopeOfService.list[2].look') }} >></div>
                        </div>
                        <div class="service_img">
                            <img src="../../public/images/service/service_4.png" />
                        </div>
                    </li>
                    <!-- <li>
                        <div class="service_img" style="margin-right: 0.1rem">
                            <img src="../../public/images/service/service_3.png" />
                        </div>
                        <div class="service_text" style="width: 7.2rem">
                            <div class="service_text_top">
                                <div>
                                    <img src="../../public/images/service/service_icon3.png" />
                                </div>
                                <p>{{ $t('Public.Service.scopeOfService.list[3].title') }}</p>
                            </div>
                            <div class="service_text_middle middle_txt">
                                {{ $t('Public.Service.scopeOfService.list[3].text') }}
                            </div>
                            <div class="service_text_bottom text_right" @click="handleRoute('/SecurityService')">{{ $t('Public.Service.scopeOfService.list[3].look') }} >></div>
                        </div>
                    </li> -->
                </ul>
            </div>
        </div>
        <my-footer></my-footer>
    </div>
</template>

<script>
// @ is an alias to /src
import MyHeader from '@/components/MyHeader.vue';
import MyFooter from '@/components/MyFooter.vue';

export default {
    name: 'Service',
    components: {
        MyHeader,
        MyFooter
    },
    data() {
        return {
            show: false
        };
    },
    methods: {
        handleRoute(path) {
            this.$router.push(path);
        }
    },
    mounted() {
        this.show = true;
    }
};
</script>
<style scoped>
.banner {
    width: 100%;
    height: 6.2rem;
    padding: 1.8rem 3.3rem 0;
    font-size: 0.22rem;
    font-weight: 300;
    background: url(../../public/images/service/banner.jpg) no-repeat;
    background-size: 100% 100%;
    background-color: #31575c;
    line-height: 2;
}
.banner_padding {
    padding: 0.8rem 3.6rem 0 !important;
}
.banner p {
    font-size: 0.54rem;
    line-height: 1.39;
    margin-bottom: 0.2rem;
}
.banner_padding p {
    margin-top: 0.3rem !important;
    margin-bottom: 0 !important;
    line-height: 1 !important;
}
.banner .banner-txt {
    text-align: justify;
}
.content {
    padding: 1rem 0 0;
    color: #333;
}
.detail_title {
    font-size: 0.32rem;
    font-weight: 600;
    text-align: center;
}
.service_type {
    margin-top: 1rem;
}
.service_type li {
    width: 100%;
    display: flex;
    padding: 0.56rem 3.5rem 0.5rem;
    position: relative;
    min-height: 3.8rem;
}
.service_type li:nth-child(2) {
    background: url(../../public/images/service/li2_bg.png) no-repeat;
    background-size: 100% 100%;
}
.service_type li:nth-child(4) {
    background: url(../../public/images/service/li4_bg.png) no-repeat;
    background-size: 100% 100%;
}
.service_type li:nth-child(1)::after,
.service_type li:nth-child(3)::after,
.service_type li:nth-child(5)::after {
    position: absolute;
    bottom: 0;
    left: 4.07rem;
    z-index: -50;
    display: block;
    content: '';
    background: url(../../public/images/service/01.png) no-repeat;
    background-size: 100% 100%;
    width: 1.69rem;
    height: 1.46rem;
}
.service_type li:nth-child(2)::after,
.service_type li:nth-child(4)::after {
    left: 12.6rem;
}
.service_type li:nth-child(3)::after {
    background: url(../../public/images/service/03.png) no-repeat;
    background-size: 100% 100%;
}
.service_type li:nth-child(5)::after {
    background: url(../../public/images/service/05.png) no-repeat;
    background-size: 100% 100%;
}
.service_text {
    width: 7.2rem;
    margin-right: 0.5rem;
}
.service_img {
    width: 4.1rem;
    height: 3.4rem;
    overflow: hidden;
}
.frist_li .service_img {
    margin-top: 0.6rem;
}
.wrap_en .frist_li .service_img {
    /* margin-top: 0; */
}
.service_img img {
    cursor: pointer;
    transition: all 0.8s;
}
.service_img img:hover {
    transform: scale(1.3);
}
.service_type li.active .service_text {
    width: 6.7rem;
}
.service_type li.active .service_img {
    margin-right: 0.62rem;
}
.service_type li.active .service_text_top {
    justify-content: flex-end;
}
.service_type li.active .service_text_top div {
    margin: 0 0 0 0.2rem;
}
.service_type li.active .service_text_middle,
.service_type li.active .service_text_bottom {
    padding: 0 0.76rem 0 0;
    text-align: right;
}
.middle_txt {
    text-align: justify;
}
.service_text_top {
    display: flex;
    align-items: center;
    font-size: 0.3rem;
    padding-top: 0.3rem;
    padding-left: 0.5rem;
}
.frist_li .service_text_top {
    padding-top: 0;
}
.service_text_top div {
    width: 0.6rem;
    margin-right: 0.2rem;
}
.service_text_middle {
    font-size: 0.16rem;
    color: #666;
    padding-left: 1.3rem;
    line-height: 2;
    /* text-align:justify; */
}
.service_text_middle p {
    font-size: 0.24rem;
    font-weight: 500;
    color: #333333;
    margin: 0.28rem 0 0.1rem;
    line-height: 1.3;
}
.service_text_bottom {
    font-size: 0.18rem;
    margin-top: 0.28rem;
    padding-left: 1.3rem;
}
.service_type li.active .service_text_bottom,
.service_type li:nth-child(3) .service_text_bottom {
    margin-top: 0.5rem;
}
.service_text_bottom:hover {
    color: #05a3e4;
    cursor: pointer;
}
.text_right {
    text-align: right;
}
</style>
